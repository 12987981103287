<!--
 * @Author: lbh
 * @Date: 2023-08-05 11:50:31
 * @LastEditors: lbh
 * @LastEditTime: 2024-07-10 09:35:56
 * @Description: file content
-->
<template>
  <div
    ref="contact-module-box"
    class="contact-module-box px-contact-module-box"
    :style="`background-color:${configs.bgColor}`"
    @click="clickItem('contact-module-box')"
  >
    <div class="left">
      <div
        class="bigTitle"
        v-text="configs[`${nowL}title`] || configs.title"
      ></div>
      <div
        class="bigDesc"
        v-html="$util.rex.getHtml(configs[`${nowL}desc`] || configs.desc)"
      ></div>
      <!-- 聯絡方式 -->
      <div class="cell">
        <div
          class="title"
          v-text="configs.contact.title"
        ></div>
        <div class="others-cell">
          <div
            v-for="(item,index) in configs.contact.types"
            :key="index"
            @click="$util.route.go(item.address)"
          >
            <img :src="item.logo" />
          </div>
        </div>
      </div>
      <!-- 地址啥的 -->
      <div class="group">
        <div
          class="item"
          v-for="(item,index) in configs.contact.labels"
          :key="index"
        >
          <div
            class="label"
            v-html="$util.rex.getHtml(item[`${nowL}label`] || item.label)"
          ></div>
          <div
            class="value"
            v-html="$util.rex.getHtml(item[`${nowL}value`] || item.value)"
          ></div>
        </div>
      </div>
      <!-- 地圖 -->
      <div class="map-box">
        <div class="mmp">
          <div
            class="map"
            ref="map"
          >
          </div>
        </div>
      </div>
    </div>
    <div class="right">
      <el-form
        :model="numberValidateForm"
        ref="numberValidateForm"
        :rules="rules"
        label-position="top"
        class="form-box"
      >
        <el-form-item
          :label="$tT('topCountryTitle','國家/地區')"
          prop="address"
        >
          <el-select
            class="_el-select"
            v-model="numberValidateForm.address"
            :placeholder="$tT('','請選擇國家/地區')"
            @change="changeAddress"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="$tT(item.key,item.label)"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$tT('topUserName','姓名')"
          prop="name"
        >
          <el-input
            type="text"
            v-model.number="numberValidateForm.name"
            autocomplete="off"
            :placeholder="$tT('topUserNamePlaceholder','請輸入您的姓名')"
          ></el-input>
        </el-form-item>

        <el-form-item
          :label="$tT('topTelphoneName','聯絡電話')"
          prop="phone"
          class="select-input-box required"
        >
          <div class="phone-box">
            <el-select
              v-model="numberValidateForm.region"
              placeholder=""
            >
              <el-option
                v-for="item in region"
                :key="item.value"
                :label="item.label"
                :value="item.label"
              >
              </el-option>
            </el-select>
            <el-input
              v-model.number="numberValidateForm.phone"
              autocomplete="off"
              :placeholder="$tT('topTelphonePlaceholder','請輸入您的聯絡電話')"
            ></el-input>
          </div>
        </el-form-item>

        <el-form-item
          :label="$tT('topRemarkName','備註')"
          class="desc-box"
        >
          <div class="cell">
            <div
              v-for="item,index in labels"
              :key="index"
              class="label"
              :class="{active:item.checked}"
              v-text="$tT(item.key,item.label)"
              @click="item.checked = !item.checked"
            ></div>
          </div>
          <el-input
            :placeholder="$tT('topRemarkPlaceholder','請輸入您需要備註的信息')"
            type="textarea"
            rows="6"
            v-model="numberValidateForm.description"
          ></el-input>
        </el-form-item>
        <el-form-item class="btn-box">
          <selfButton
            id="selfButton"
            :loading="btnLoading"
            @onClick="submitForm('numberValidateForm')"
          >{{$tT('topRAppointmentDemonstration','預約示範')}}</selfButton>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import langMixin from '../../mixins/lang';
import { Loader } from '@googlemaps/js-api-loader';
import ScrollTrigger from '@terwanerik/scrolltrigger';
const trigger = new ScrollTrigger();
import { gsap, TweenMax } from "gsap";
import { addHomeUser } from "@/js/api/api";

export default {
  name: "contact-module",
  mixins: [langMixin],
  props: {
    configs: {
      default () {
        return {}
      }
    },
    Xindex: {
      default () {
        return '';
      },
    },
  },
  data () {
    return {
      options: [
        {
          value: '中國香港',
          label: '中國香港',
          key: "topHKName"
        },
        {
          value: '中國大陸',
          label: '中國大陸',
          key: "topChinaName"
        },
        {
          value: '中國澳門',
          label: '中國澳門',
          key: "topMaName"
        },
      ],
      region: [
        {
          value: '852',
          label: '+852',
        },
        {
          value: '853',
          label: '+853',
        },
        {
          value: '+86',
          label: '+86',
        },
      ],
      numberValidateForm: {
        name: '',
        address: '中國香港',
        restaurantName: '',
        phone: '',
        region: '+852',
        description: '',
        emailAddress: '',
        restaurantType: '1',
        source: 1
      },
      rules: {
        phone: [
          {
            required: true,
            validator: (rule, value, callback) => {
              let region = this.numberValidateForm.region;
              if (value === '') {
                callback(new Error(this.$tT('topTelphonePlaceholder', '請輸入電話號碼')));
              } else {
                let p = /[1][3-8]\d{9}/g;
                let hk = /([2|3|4|5|6|8|9])\d{7}/g;
                let am = /\d{8}/g;
                if (region.includes('852') && !hk.test(value)) {
                  callback(new Error(this.$tT('topPleasePhoneConfirm', '請輸入正確的聯絡電話')));
                } else if (region.includes('853') && !am.test(value)) {
                  callback(new Error(this.$tT('topPleasePhoneConfirm', '請輸入正確的聯絡電話')));
                } else if (region.includes('86') && !p.test(value)) {
                  callback(new Error(this.$tT('topPleasePhoneConfirm', '請輸入正確的聯絡電話')));
                } else {
                  callback();
                }
              }
            },
            trigger: 'blur'
          }
        ]
      },
      btnLoading: false,
      isShowMeg: false,
      markers: [],
      map: null,
      labels: [
        { label: "連鎖店", key: "remarkLabel1", checked: false },
        { label: "加盟店", key: "remarkLabel2", checked: false },
        { label: "新店開業", key: "remarkLabel3", checked: false },
        { label: "CMR會員系統", key: "remarkLabel4", checked: false },
        { label: "餐檯碼", key: "remarkLabel5", checked: false },
        { label: "外賣系統", key: "remarkLabel6", checked: false },
      ]
    }
  },
  computed: {
    isEdit () {
      return this.$store.getters.getIsEdit;
    }
  },
  mounted () {

    this.$idb.get('langFile_').then(result => {
      console.log(result);
      let labels = [];
      for (let i in result) {
        let label = result[i]
        if (label.key && label.key.includes('remarkLabel') && label.use) {
          labels.push({
            label: label.HK,
            key: label.key,
            checked: false
          })
        }
      }

      let labels_ = this.labels;
      for (let i in labels) {
        for (let j = 0; j < labels_.length; j += 1) {
          if (labels_[j].key == labels[i].key) {
            labels_.splice(j, 1)
            j -= 1;
          }
        }
      }
      this.labels = [...labels, ...labels_]
    })

    this.initMap();
    trigger.add(this.$refs['contact-module-box'], {
      once: true,
      offset: {
        element: {
          x: 0,
          y: (trigger, rect, direction) => {
            return 0.2
          }
        },
        viewport: {
          y: (trigger, frame, direction) => {
            return 0;// trigger.visible ? 0 : 0.2
          }
        },
      },
      toggle: {
        callback: {
          in: () => {
            // 設置標題動畫
            TweenMax.from(this.$refs['contact-module-box'].querySelector('.bigTitle'), {
              opacity: 0,
              y: 120,
              duration: 1,
              immediateRender: true
            })
            // 設置 描述動畫
            TweenMax.from(this.$refs['contact-module-box'].querySelector('.bigDesc'), {
              opacity: 0,
              delay: 0.3,
              y: 120,
              duration: 1,
              immediateRender: true
            })

            // 設置 描述動畫
            TweenMax.from(this.$refs['contact-module-box'].querySelector('.cell'), {
              opacity: 0,
              delay: 0.6,
              y: 120,
              duration: 1,
              immediateRender: true
            })

            TweenMax.from(this.$refs['contact-module-box'].querySelector('.group'), {
              opacity: 0,
              delay: 0.6,
              y: 120,
              duration: 1,
              immediateRender: true
            })

            TweenMax.from(this.$refs['contact-module-box'].querySelector('.map-box'), {
              opacity: 0,
              delay: 0.6,
              y: 120,
              duration: 1,
              immediateRender: true
            })
            TweenMax.from(this.$refs['contact-module-box'].querySelector('.right'), {
              opacity: 0,
              delay: 0.6,
              y: 120,
              duration: 1,
              immediateRender: true
            })
          },
          out: () => {
            // this.showNumber = false;
          },
        }
      }
    })
  },
  methods: {
    initMap () {
      const loader = new Loader({
        apiKey: 'AIzaSyCujUc1iFYa8GBWthpm3IOJp6_rxcBjI-I', //填写google控制台中的apikey
        libraries: ['places', ''],
      });
      let lat = this.configs.contact.address.mapX || 22.3392;
      let lng = this.configs.contact.address.mapY || 114.1511;
      loader.load().then(() => {
        let map = new google.maps.Map(this.$refs.map, {
          center: {
            lat: Number(lat),
            lng: Number(lng),
          },
          mapTypeId: google.maps.MapTypeId[this.mapType],
          zoom: 16,
          mapTypeControl: false,
          fullscreenControl: false,
          streetViewControl: false,
          language: 'zh-hk',
        });
        this.map = map;
        this.service = new google.maps.places.PlacesService(map);
        let position;
        if (
          lat == '' ||
          lng == '' ||
          lat == null ||
          lng == null
        ) {
          position = {
            lat: 22.3392,
            lng: 114.1511,
          };
        } else {
          position = {
            lat: Number(lat),
            lng: Number(lng),
          };
        }
        const marker = new google.maps.Marker({
          position: { lat: position.lat, lng: position.lng },
          draggable: false,
          map: map,
          title: '',
        });

        // 创建一个信息窗口并将其与标记关联
        var infoWindow = new google.maps.InfoWindow({
          content: `<img style="width:100px;" src="https://ricepon.oss-cn-hongkong.aliyuncs.com//images/webAdmin//1666320593603png" />`,
          closeOnClick: false
        });
        infoWindow.open(map, marker);

        this.markers.push(marker);
      });
    },

    submitForm (formName) {

      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          // alert('submit!');
          console.log(this.numberValidateForm);
          let form = this.$util.object.clone(this.numberValidateForm);
          form.phone = form.region + form.phone;
          let eleId = document.querySelector('#selfButton')
          let labels = this.labels;
          let sel = [];
          for (let i in labels) {
            if (labels[i].checked) sel.push(labels[i].label)
          }
          form.description = `${sel.join(',')} ${form.description}`

          addHomeUser(form).then(res => {
            this.btnLoading = false;
            if (res.success) {
              this.isSubmit = true;
              if (eleId) {
                this.isShowMeg = true
                eleId.classList.add('clickButtonStyle')
              }

              this.numberValidateForm.name = '';
              this.numberValidateForm.phone = '';
              this.numberValidateForm.description = '';
              for (let i in labels) {
                labels[i].checked = false;
              }

              this.$alert(this.$tT('topSubmitAlertSuccessText', '您的預約申請已提交成功，我們將會在24小時内與你取得聯係！'), this.t_('topSubmitAlertTitle', '溫馨提示'), {
                confirmButtonText: this.$tT('topSubmitAlertBtn', '確定'),
                callback: action => {
                }
              });

            } else {
              this.$message.error(this.$tT('topSubmitAlertError', '發生錯誤,請檢查您的資料信息'));
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm () {
      this.closeLayer();
    },
    closeLayer () {
      this.numberValidateForm = {
        name: '',
        address: this.$tT('topHKName', '中國香港'),
        restaurantName: '',
        phone: '',
        region: '+852',
        description: '',
      }
    },
    clickItem (e, x) {
      if (this.isEdit) {
        this.$emit('clickItem', e, x);
      }
    },

    changeAddress () {
      let { address, phone } = this.numberValidateForm;
      if (!phone) {
        let topHKName = this.$tT('topHKName', '中國香港')
        let topChinaName = this.$tT('topChinaName', '中國大陸')
        let topMaName = this.$tT('topMaName', '中國澳門')
        if (address == topHKName) {
          this.numberValidateForm.region = '+852'
        } else if (address == topChinaName) {
          this.numberValidateForm.region = '+86'
        } else if (address == topMaName) {
          this.numberValidateForm.region = '+853'
        }
      }
    },
  }
}
</script>

<style lang="less" scoped>
.contact-module-box {
  padding: 157px 116px 122px;
  display: flex;
  .left {
    display: flex;
    width: 922px;
    flex-direction: column;
    .bigTitle {
      font-size: 44px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
    }
    .bigDesc {
      font-size: 26px;
      font-family: "Microsoft Yahei";
      font-weight: 200;
      line-height: 40px;
      color: #ffffff;
      margin-top: 6px;
      margin-bottom: 20px;
    }

    .cell {
      margin-top: auto;
      .title {
        font-size: 26px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
      }
      .others-cell {
        display: flex;
        margin-top: 20px;
        div {
          margin-left: 37px;
          &:nth-of-type(1) {
            margin-left: 0;
          }
        }
        img {
          width: 80px;
          height: 80px;
        }
      }
    }
    .group {
      margin-top: 24px;
      .item {
        display: flex;
        font-size: 26px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        margin-top: 10px;
      }
    }
    .map-box {
      margin-top: 18px;
      .mmp {
        border-radius: 14px;
        overflow: hidden;
      }
      .map {
        width: 922px;
        height: 320px;
        border-radius: 14px;
      }
      position: relative;
      &::before {
        position: absolute;
        content: "";
        border-width: 8px;
        border-style: solid;
        border-color: transparent #fff #fff transparent;
        top: -15px;
        left: 36px;
        transform: rotate(225deg);
        transform-origin: left bottom;
      }
    }
  }
  .right {
    flex: 1;
    margin-left: 50px;
    background: #ffffff;
    box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.1),
      -3px -3px 15px 0px rgba(0, 0, 0, 0.1);
    border-radius: 14px;
    padding: 50px 68px 48px;
    .form-box {
      & /deep/ .el-form-item__label {
        padding: 0;
      }
      ._el-select {
        width: 100%;
      }
      .phone-box {
        display: flex;
        & /deep/ .el-select {
          width: 120px;
          margin-right: 10px;
        }
      }
      .btn-box {
        & /deep/ .el-form-item__content {
          display: flex;
          justify-content: center;
        }
      }
    }
    .desc-box {
      .cell {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        .label {
          padding: 6px;
          font-size: 16px;
          border: 0.5px solid #ddd;
          color: #333;
          line-height: 18px;
          word-break: break-all;
          margin: 4px;
          border-radius: 8px;
          cursor: pointer;
          transition: 0.3s;
          &.active {
            border-color: var(--themeColor);
            color: var(--themeColor);
          }
        }
      }
    }
  }
}

@media screen and(max-width:768px) {
  .px-contact-module-box {
    padding: 34px 12px !important;
    flex-direction: column;
    .left {
      width: 100%;

      .bigTitle {
        font-size: 22px !important;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        text-align: center;
      }
      .bigDesc {
        font-size: 16px !important;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        margin-top: 4px;
        text-align: center;
        padding: 0 50px;
      }
      .cell {
        margin-top: 26px !important;
        .title {
          font-size: 16px !important;
          font-family: PingFang SC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
        }
        .others-cell {
          margin-top: 12px !important;
          img {
            width: 30px !important;
            height: 30px !important;
          }
        }
      }
      .group {
        margin-top: 10px !important;
        .item {
          margin-top: 10px !important;
          font-size: 14px !important;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
        }
      }

      .map-box {
        --mw: calc(100vw - 24px);
        width: var(--mw);
        height: calc(var(--mw) / 2.88);
        margin-top: 14px;
        .mmp {
          border-radius: 6px !important;
          overflow: hidden !important;
          width: 100% !important;
          height: 100% !important;
          .map {
            width: 100% !important;
            height: 100% !important;
            border-radius: 6px !important;
            overflow: hidden !important;
            z-index: 0;
          }
        }
        &::before {
          position: absolute;
          content: "";
          border-width: 4px;
          border-style: solid;
          border-color: transparent #fff #fff transparent;
          top: -7px;
          left: 26px;
          transform: rotate(225deg);
          transform-origin: left bottom;
        }
      }
    }
    .right {
      margin-left: 0;
      margin-top: 12px;
      border-radius: 6px;
      .desc-box {
        .cell {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          .label {
            padding: 6px;
            font-size: 14px;
            border: 0.5px solid #ddd;
            color: #333;
            line-height: 18px;
            word-break: break-all;
            margin: 4px;
            border-radius: 8px;
            cursor: pointer;
            transition: 0.3s;
            &.active {
              border-color: var(--themeColor);
              color: var(--themeColor);
            }
          }
        }
      }
    }
  }
}

@media screen and(max-width:600px) {
  .px-contact-module-box {
    padding: 34px 12px !important;
    flex-direction: column;
    .left {
      width: 100%;

      .bigTitle {
        font-size: 20px !important;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        text-align: center;
      }
      .bigDesc {
        font-size: 14px !important;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        margin-top: 4px;
        text-align: center;
        line-height: 20px;
      }
      .cell {
        margin-top: 26px !important;
        .title {
          font-size: 14px !important;
          font-family: PingFang SC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
        }
        .others-cell {
          margin-top: 10px !important;
          img {
            width: 30px !important;
            height: 30px !important;
          }
        }
      }
      .group {
        margin-top: 10px !important;
        .item {
          margin-top: 10px !important;
          font-size: 12px !important;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
        }
      }

      .map-box {
        --mw: calc(100vw - 24px);
        width: var(--mw);
        height: calc(var(--mw) / 2.88);
        margin-top: 14px;
        .map {
          width: 100%;
          height: 100%;
          border-radius: 6px;
          & /deep/ .gm-style {
            border-radius: 6px !important;
          }
        }
      }
    }
    .right {
      margin-left: 0;
      margin-top: 12px;
    }
  }
}
</style>